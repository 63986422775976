/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect } from "react"
import { useState } from "react"
import { Disclosure, RadioGroup, Tab } from "@headlessui/react"
import { StarIcon } from "@heroicons/react/solid"
import DeliveryIcon from "../assets/icons/delivery.svg"
import {
  getCart,
  getSingleAsset,
  getSingleProduct,
  sessToken,
  processPayment,
} from "../helpers/DrupalHelper"
import { HeartIcon, MinusSmIcon, PlusSmIcon } from "@heroicons/react/outline"
import Layout from "../components/Layout/Pages"
import { navigate } from "gatsby"
import ProductImageBox from "../components/ProductImageBox"
import AssetsCard from "../components/Cards/AssestCard"
import RelatedProduct from "../components/RelatedProduct"
import MetaUpdated from "../components/Elements/MetaUpdated"
import MetaDelivery from "../components/Elements/MetaDelivery"
import Separator from "../components/Elements/Separator"
import CircleAvatar from "../components/Elements/CircleAvatar"
import AddToCartBox from "../components/Elements/AddToCartBox"
import Tag from "../components/Elements/Tag"
import Tags from "../components/Elements/Tags"
import Button from "../components/Elements/Button"
import CartCard from "../components/Cards/CartCard"
import CartContext from "../context/Cart/CartContext"
import StateContext from "../context/state/StateContext"
import BannerSimple from "../components/Commons/BannerSimple"
import Container from "../components/Container"
import { Link } from "gatsby"
import ImageCardSmall from "../components/Elements/ImageCardSmall"
import FloatingInput from "../components/Elements/Form/FloatingInput"
import FloatingSelect from "../components/Elements/Form/FloatingSelect"
import SectionCard from "../components/Cards/Dashboard/SectionCard"
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk"
import NotificationContext from "../context/Notification/NotificationContext"
import { Table, Tr, Td } from "../components/Table/TableElements"
import { useForm } from "react-hook-form"
import { useMediaQuery } from "react-responsive"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const CheckOut = () => {
  const { toggleNotification } = useContext(NotificationContext)
  const { state } = useContext(StateContext)

  // const [selectedColor, setSelectedColor] = useState(product.colors[0])
  const {
    cart,
    handleRemoveClick,
    handleProcessCart,
    setLoading,
    setCartItems,
    prevCart,
    setOrderConfirmation,
  } = useContext(CartContext)

  const structuredCart = cart.reduce((acc, curr) => {
    curr.addresses.forEach(address => {
      const newCurr = {
        ...curr,
        addresses: [address],
      }
      const found = acc.find(
        item => item.addresses.address_id === address.address_id
      )
      if (found) {
        found.items.push(newCurr)
      } else {
        acc.push({
          addresses: address,
          items: [newCurr],
        })
      }
    })
    return acc
  }, [])
  console.log(structuredCart, "structuredCart")
  const cart_obj = JSON.parse(localStorage.getItem("cart_obj"))
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  })
  const CartWithPrice = cart.filter((currentProduct, index) => {
    return currentProduct.price > 0
  })

  const totalProductPrice = CartWithPrice.reduce((preValue, currValue) => {
    // console.log(currValue,'curr!')
    return (
      preValue +
      parseFloat(currValue.price) * parseInt(currValue.addresses[0].quantity)
    )
  }, 0)

  // const totalProductWithTex = (
  //   (13 / 100) * totalProductPrice.toFixed(2) +
  //   totalProductPrice
  // ).toFixed(2)

  const CartWithoutPrice = cart.filter((currentProduct, index) => {
    return currentProduct.price <= 0
  })

  const fontStyles = `font-family: "Jost";
     font-style: normal;
     font-weight: 400;
     font-size: 15px;
     line-height: 28px;
     letter-spacing: 0.02em;
     color: #717171;`

  const { register, handleSubmit, control, getValues } = useForm()
  const onSubmit = data => console.log(data)

  const calculateTotalPriceCart = () => {
    // just add all prices with tex of structuredCart
    let total = 0
    structuredCart.forEach(item => {
      const { addresses, items } = item
      const matchedCart = cart_obj?.find(
        c => c?.address_id === addresses.address_id
      )
      const { price, quantity } = items.reduce(
        (acc, curr) => {
          const address = curr.addresses[0] // Get the first (and only) address
          const itemPrice = parseFloat(curr.price)
          const itemQuantity = parseInt(address.quantity)

          return {
            price: acc.price + itemPrice * itemQuantity, // Add price * quantity to total price
            quantity: acc.quantity + itemQuantity, // Add quantity to total quantity
          }
        },
        { price: 0, quantity: 0 }
      )
      const totalProductWithTex = (
        (13 / 100) * price.toFixed(2) +
        price
      ).toFixed(2)
      total += parseFloat(totalProductWithTex)
    })
    return total
  }

  const appId =
    true && process.env.GATSBY_API_BASE === "https://api.useo2o.com"
      ? `sq0idp-fG7AfuupCwSJWSKpJsZixQ`
      : `sandbox-sq0idb-sf_PvfVlgyPkI4D08DJ89Q`
  const locationId =
    true && process.env.GATSBY_API_BASE === "https://api.useo2o.com"
      ? `L3SM02NK2ANG5`
      : `LJ2T5KK3KF308`

  // Cocoon Sandbox:
  // const appId = "sandbox-sq0idb-k51BswgIFDiCgT5qcjW74Q"
  // const locationId = "LKSWE7STV57PW"

  return (
    <Layout metaTitle={"Checkout"}>
      <BannerSimple heading={"Checkout"} />
      <Container>
        <div className={`flex ${isTablet ? "flex-col" : ""} mb-10`}>
          <div className="w-full lg:w-2/3 lg:pr-[30px]">
            {structuredCart?.map((item, index) => {
              const { addresses, items } = item
              //get matched cart object from cart_obj
              const matchedCart = cart_obj?.find(
                c => c?.address_id === addresses.address_id
              )

              const { price, quantity } = items.reduce(
                (acc, curr) => {
                  const address = curr.addresses[0] // Get the first (and only) address
                  const itemPrice = parseFloat(curr.price)
                  const itemQuantity = parseInt(address.quantity)

                  return {
                    price: acc.price + itemPrice * itemQuantity, // Add price * quantity to total price
                    quantity: acc.quantity + itemQuantity, // Add quantity to total quantity
                  }
                },
                { price: 0, quantity: 0 }
              )
              const totalProductWithTex = (
                (13 / 100) * price.toFixed(2) +
                price
              ).toFixed(2)

              return (
                <div className="border border-[#EBEBEB] rounded-[8px] mb-4">
                  <div className="flex items-center p-[15px] md:p-[30px]">
                    <h1
                      className={`${
                        isMobile ? `text-semibold-16` : `text-semibold-18`
                      } w-[60px]`}
                    >
                      #{index + 1}
                    </h1>
                    <div className="flex flex-col gap-3">
                      <h1
                        className={`${
                          isMobile ? `text-semibold-16` : `text-semibold-18`
                        } w-full`}
                      >
                        {addresses?.address_name?.toUpperCase()}
                      </h1>
                      {!isTablet ? (
                        <div
                          css={css`
                            font-family: "Jost";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 16px;
                            display: inline-block;
                            padding: 0;
                            letter-spacing: 0.02em;
                            color: #717171;
                            display: flex;
                            gap: 40px;
                          `}
                        >
                          {addresses?.company_name !== null &&
                            addresses?.company_name !== "" && (
                              <p>{addresses?.company_name}</p>
                            )}
                          {((addresses?.first_name !== null &&
                            addresses?.first_name !== "") ||
                            (addresses?.last_name !== null &&
                              addresses?.last_name !== "")) && (
                            <span className="flex gap-1">
                              {addresses?.first_name && (
                                <p>{addresses?.first_name}</p>
                              )}
                              {addresses?.last_name && (
                                <p>{addresses?.last_name}</p>
                              )}
                            </span>
                          )}

                          {addresses?.address !== null &&
                            addresses?.address !== "" && (
                              <p>{addresses?.address}</p>
                            )}
                          {addresses?.phone !== null &&
                            addresses?.phone !== "" && (
                              <p>{addresses?.phone}</p>
                            )}
                        </div>
                      ) : (
                        <>
                          <p
                            css={css`
                              font-family: "Jost";
                              font-style: normal;
                              font-weight: 400;
                              font-size: 13px;
                              line-height: 16px;
                              display: inline-block;
                              padding: 0;
                              letter-spacing: 0.02em;
                              color: #717171;
                            `}
                          >
                            {addresses?.address !== null &&
                              addresses?.address !== "" && (
                                <p>{addresses?.address}</p>
                              )}
                          </p>
                          <div
                            css={css`
                              font-family: "Jost";
                              font-style: normal;
                              font-weight: 400;
                              font-size: 13px;
                              line-height: 16px;
                              display: inline-block;
                              padding: 0;
                              letter-spacing: 0.02em;
                              color: #717171;
                              display: flex;
                              justify-content: space-between;
                              gap: 20px;
                              flex-wrap: wrap;
                            `}
                          >
                            {addresses?.company_name !== null &&
                              addresses?.company_name !== "" && (
                                <p>{addresses?.company_name}</p>
                              )}
                            {((addresses?.first_name !== null &&
                              addresses?.first_name !== "") ||
                              (addresses?.last_name !== null &&
                                addresses?.last_name !== "")) && (
                              <span className="flex gap-1">
                                {addresses?.first_name && (
                                  <p>{addresses?.first_name}</p>
                                )}
                                {addresses?.last_name && (
                                  <p>{addresses?.last_name}</p>
                                )}
                              </span>
                            )}
                            {addresses?.phone !== null &&
                              addresses?.phone !== "" && (
                                <p>{addresses?.phone}</p>
                              )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <hr class="h-px bg-[#EBEBEB] border-0 ml-6 mr-6" />

                  <div className="px-6">
                    <Table
                      className="checkoutTable"
                      columns={["Asset", "Price", "Qty", "Total"]}
                      // size={["expand", "expand", "shrink", "shrink", "shrink"]}
                      fit
                      flush
                    >
                      {CartWithPrice &&
                        CartWithPrice.length > 0 &&
                        items.map((v, k) =>
                          v.addresses.map((s, t) => (
                            <Tr key={k}>
                              <Td>
                                <Link
                                  to={`/asset/${v.asset_id}`}
                                  className={`flex ${
                                    isTablet ? `flex-col` : "space-x-4"
                                  } items-start`}
                                >
                                  <ImageCardSmall
                                    image={v.asset_image}
                                    alt={v.asset_title}
                                    size={60}
                                  />
                                  <div className="flex flex-col">
                                    <h3 className="text-med-15 pr-2">
                                      {v.asset_title}
                                    </h3>
                                    <span className="text-reg-13">
                                      {v.asset_sku}
                                    </span>
                                  </div>
                                </Link>
                              </Td>

                              {
                                //   <Td>
                                //   <p
                                //     className={`text-reg-13`}
                                //     // css={css`
                                //     //   text-transform: uppercase;
                                //     //   // font-weight: 500;
                                //     //   font-size: 13px;
                                //     //   letter-spacing: 0.02rem;
                                //     //   color: #717171;
                                //     // `}
                                //   >
                                //     {s.store_name}
                                //   </p>
                                // </Td>
                              }
                              <Td>
                                <span className="text-reg-13">
                                  {parseFloat(v.price) *
                                    parseInt(s.quantity) ===
                                  0
                                    ? "FREE"
                                    : "$" +
                                      (
                                        parseFloat(v.price) *
                                        parseInt(s.quantity)
                                      ).toFixed(2)}
                                </span>
                              </Td>
                              <Td>
                                <span className="text-reg-13">
                                  {s.quantity}
                                </span>
                              </Td>

                              <Td>
                                <span className="text-reg-13">
                                  $
                                  {(
                                    parseFloat(v.price) * parseInt(s.quantity)
                                  ).toFixed(2)}
                                </span>
                              </Td>
                            </Tr>
                          ))
                        )}
                      {
                        // CartWithoutPrice &&
                        // CartWithoutPrice.length > 0 &&
                        // items.map((v, k) =>
                        //   v.addresses.map((s, t) => (
                        //     <Tr key={k}>
                        //       <Td>
                        //         <Link
                        //           to={`/asset/${v.asset_id}`}
                        //           className={`flex ${
                        //             isTablet ? `flex-col` : "space-x-4"
                        //           } items-start`}
                        //         >
                        //           <ImageCardSmall
                        //             image={v.asset_image}
                        //             alt={v.asset_title}
                        //             size={60}
                        //           />
                        //           <div className="flex flex-col">
                        //             <h3 className="text-med-15 pr-2">
                        //               {v.asset_title}
                        //             </h3>
                        //             <span className="text-reg-13">
                        //               {v.asset_sku}
                        //             </span>
                        //           </div>
                        //         </Link>
                        //       </Td>
                        //       {
                        //         //   <Td>
                        //         //   <p
                        //         //     className={`text-reg-13`}
                        //         //     // css={css`
                        //         //     //   text-transform: uppercase;
                        //         //     //   // font-weight: 500;
                        //         //     //   font-size: 13px;
                        //         //     //   letter-spacing: 0.02rem;
                        //         //     //   color: #717171;
                        //         //     // `}
                        //         //   >
                        //         //     {s.store_name}
                        //         //   </p>
                        //         // </Td>
                        //       }
                        //       <Td>
                        //         <span className="text-reg-13">Free</span>
                        //       </Td>
                        //       <Td>
                        //         <span className="text-reg-13">
                        //           {s.quantity}
                        //         </span>
                        //       </Td>
                        //       <Td>
                        //         <span className="text-reg-13">$0</span>
                        //       </Td>
                        //     </Tr>
                        //   ))
                        // )
                      }
                      <Tr>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td>
                          <span className="text-med-13">Subtotal</span>
                        </Td>
                        <Td>
                          <span className="text-reg-13">
                            ${price.toFixed(2)}
                          </span>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td>
                          <span className="text-med-13">Shipping</span>
                        </Td>
                        <Td>
                          <span className="text-reg-13">
                            {matchedCart?.shipping === "standard"
                              ? "Standard"
                              : matchedCart?.shipping === "express"
                              ? "Express"
                              : "Self-Pickup"}
                          </span>
                        </Td>
                      </Tr>
                      {matchedCart?.giftOrder === true && (
                        <Tr>
                          <Td></Td>
                          <Td></Td>
                          <Td></Td>
                          <Td>
                            <span className="text-med-13">Gift Order</span>
                          </Td>
                        </Tr>
                      )}
                      {matchedCart?.rushOrder === true && (
                        <Tr>
                          <Td></Td>
                          <Td></Td>
                          <Td></Td>
                          <Td>
                            <span className="text-med-13">Rush Order</span>
                          </Td>
                        </Tr>
                      )}
                      <Tr>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td>
                          <span className="text-med-13">HST @ 13%</span>
                        </Td>
                        <Td>
                          <span className="text-reg-13">
                            {" "}
                            ${((13 / 100) * price.toFixed(2)).toFixed(2)}
                          </span>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td>
                          <span className="text-med-13">Total</span>
                        </Td>
                        <Td>
                          <span className="text-reg-13">
                            ${totalProductWithTex}
                          </span>
                        </Td>
                      </Tr>
                    </Table>
                  </div>
                </div>
              )
            })}
            <br />
            <br />
            <p className="text-reg-17 mb-4">All prices in Canadian dollar.</p>
          </div>

          <div className="w-full lg:w-1/3">
            <SectionCard title={"Payment Details"}>
              {/*<p className="text-reg-17 mb-4">
                Items will be delivered to your chosen store(s) in the
                quantities shown beside.
              </p>*/}
              {/*  address form  */}
              {/*<SectionCard title={"Payment Address"}> */}
              <h3 className="text-med-16 mb-2">
                {`Subtotal : $${calculateTotalPriceCart().toFixed(2)}`}
              </h3>
              <h3 className="text-med-16 mb-2">Billing Address</h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FloatingInput
                  label="Address Line 1 *"
                  hookForm
                  name="address_line_1"
                  type="text"
                  register={register}
                  required
                />
                <FloatingInput
                  label="Address Line 2 *"
                  hookForm
                  name="address_line_1"
                  type="text"
                  register={register}
                  required
                />
                <div className="flex space-x-2">
                  <FloatingInput
                    label="City *"
                    hookForm
                    name="locality"
                    type="text"
                    register={register}
                    required
                  />
                  <FloatingSelect
                    label="Province *"
                    name="administrative_district_level_1"
                    options={[
                      { value: "Alberta", label: "AB" },
                      { value: "British Columbia", label: "BC" },
                      { value: "Manitoba", label: "MB" },
                      { value: "New Brunswick", label: "NB" },
                      { value: "Newfoundland and Labrador", label: "NL" },
                      { value: "Northwest Territories", label: "NT" },
                      { value: "Nova Scotia", label: "NS" },
                      { value: "Nunavut", label: "NU" },
                      { value: "Ontario", label: "ON" },
                      { value: "Prince Edward Island", label: "PE" },
                      { value: "Quebec", label: "QC" },
                      { value: "Saskatchewan", label: "SK" },
                      { value: "Yukon Territory", label: "YT" },
                    ]}
                    control={control}
                    required
                    hookForm
                  />
                </div>
                <div className="flex space-x-2">
                  <FloatingInput
                    label="Postal Code *"
                    hookForm
                    name="postal_code"
                    type="text"
                    register={register}
                    required
                  />
                  <FloatingSelect
                    label="Country *"
                    name="country"
                    options={[{ value: "CA", label: "Canada" }]}
                    control={control}
                    required
                    hookForm
                  />
                </div>
                {/*
                <input
                  className={classNames(
                    "block py-[16px] px-[18px] w-full bg-transparent border rounded-[8px]  appearance-none  focus:outline-none focus:ring-0  peer}"
                    // error
                    //   ? "border-red-600 focus:border-red-600 focus:text-red-600 "
                    //   : "border-[#EBEBEB] focus:border-[#222] focus:text-[#222]"
                  )}
                  css={css`

                    border-radius: 8px;
                    ${fontStyles}
                  `}
                  {...register("AddressLine1")}
                  placeholder="Address Line 1 "
                />
                <input
                  className={classNames(
                    "block py-[16px] px-[18px] w-full bg-transparent border rounded-[8px]  appearance-none  focus:outline-none focus:ring-0  peer}"
                    // error
                    //   ? "border-red-600 focus:border-red-600 focus:text-red-600 "
                    //   : "border-[#EBEBEB] focus:border-[#222] focus:text-[#222]"
                  )}
                  css={css`

                    border-radius: 8px;
                    ${fontStyles}
                  `}
                  {...register("AddressLine2")}
                  placeholder="Address Line 2 "
                />
                <div className="flex space-x-2">
                  <input
                    className={classNames(
                      "block py-[16px] px-[18px] w-full bg-transparent border rounded-[8px]  appearance-none  focus:outline-none focus:ring-0  peer}"
                      // error
                      //   ? "border-red-600 focus:border-red-600 focus:text-red-600 "
                      //   : "border-[#EBEBEB] focus:border-[#222] focus:text-[#222]"
                    )}
                    css={css`

                      border-radius: 8px;
                      ${fontStyles}
                    `}
                    {...register("locality")}
                    placeholder="City "
                  />

                  <select
                    className={classNames(
                      "block py-[16px] px-[18px] w-full bg-transparent border rounded-[8px]  appearance-none  focus:outline-none focus:ring-0  peer}"
                      // error
                      //   ? "border-red-600 focus:border-red-600 focus:text-red-600 "
                      //   : "border-[#EBEBEB] focus:border-[#222] focus:text-[#222]"
                    )}
                    css={css`

                      border-radius: 8px;
                      ${fontStyles}
                    `}
                    {...register("administrative_district_level_1")}
                    placeholder="Postal Code "
                  >
                    <option value="Alberta">Alberta</option>
                    <option value="British Columbia">British Columbia</option>

                    <option value="Manitoba">Manitoba</option>

                    <option value="New Brunswick">New Brunswick</option>

                    <option value="Newfoundland and Labrador">
                      Newfoundland and Labrador
                    </option>

                    <option value="Northwest Territories">
                      Northwest Territories
                    </option>

                    <option value="Nova Scotia">Nova Scotia</option>

                    <option value="Nunavut">Nunavut</option>

                    <option value="Prince Edward Island">
                      Prince Edward Island
                    </option>

                    <option value="Quebec">Quebec</option>

                    <option value="Saskatchewan">Saskatchewan</option>

                    <option value="Yukon Territory">Yukon Territory</option>
                  </select>
                </div>
                <div className="flex space-x-2">
                  <input
                    className={classNames(
                      "block py-[16px] px-[18px] w-full bg-transparent border rounded-[8px]  appearance-none  focus:outline-none focus:ring-0  peer}"
                    )}
                    css={css`

                      border-radius: 8px;
                      ${fontStyles}
                    `}
                    {...register("Postal Code")}
                    placeholder="Postal Code "
                  />
                  <select
                    className={classNames(
                      "block py-[16px] px-[18px] w-full bg-transparent border rounded-[8px]  appearance-none  focus:outline-none focus:ring-0  peer}"

                    )}
                    css={css`

                      border-radius: 8px;
                      ${fontStyles}
                    `}
                    {...register("country")}
                    placeholder="Country "
                  >
                    <option value="female">Canada</option>

                  </select>
                </div>*/}
                {/*<Button red full>
                    <input type="submit" />
                  </Button>*/}
              </form>
              {/*  </SectionCard> */}
              {/* payment form */}

              {/*<br />
              <br />
              <br/   >*/}
              <h3 className="text-med-16 mb-2">Card Details</h3>
              <PaymentForm
                applicationId={appId}
                locationId={locationId}
                cardTokenizeResponseReceived={async (tok, buyer) => {
                  setLoading(true)

                  const vars = getValues()
                  try {
                    await processPayment(state.token, {
                      card_nonce: tok.token,
                      billing_address: vars,
                    }).then(async response => {
                      // console.log(response, 'response')
                      // if (
                      //   response.payment.status ||
                      //   (Array.isArray(response) &&
                      //     response.length > 0 &&
                      //     response[0].status)
                      // ) {

                      if ("errors" in response) {
                        let errorStr = ""
                        response.errors.map((i, k) => {
                          errorStr = errorStr + i.message
                        })
                        console.log(response.errors)
                        setLoading(false)
                        toggleNotification({ content: errorStr, error: true })
                      } else if ("message" in response) {
                        toggleNotification({
                          content: response.message,
                          error: true,
                        })
                        setLoading(false)
                        navigate("/cart")
                      } else {
                        // console.log('hi');
                        // let items = await getCart()
                        // setCartItems([...items])
                        // setPrevCart([...items])
                        setOrderConfirmation(response)

                        navigate(`/order-confirmation`, {
                          // state: {
                          //   orderConfirmation: response,
                          //   prevCart: prevCart,
                          // },
                        })
                        setLoading(false)
                        toggleNotification({
                          content: `Success! Your order has been placed.`,
                        })
                        localStorage.removeItem("cart_obj")
                      }
                      // console.log("success")
                      // } else {
                      //   // console.log("not found")
                      //   toggleNotification({
                      //     content: `You must order at least two unique assets to each store to checkout.`,
                      //   })
                      // }
                    })
                  } catch (e) {
                    setLoading(false)
                    toggleNotification({
                      content: `An unknown error occurred while processing your payment. Do not reattempt payment, contact us instead.`,
                      error: true,
                    })
                  }

                  //   fetch(
                  //   "https://cocoon-useo2o.pantheonsite.io/api/process-payment",
                  //   // " http://localhost:5000/",
                  //   {
                  //     method: "POST",
                  //     headers: {
                  //       "Content-Type": "application/json",
                  //     },
                  //     body: JSON.stringify({ sourceId: token.token }),
                  //   }
                  // )

                  // console.log(await response.json())

                  // const serverResponse = await response.json()
                  // console.log(serverResponse.payment.status)
                  // console.log(response)
                }}
              >
                <CreditCard
                  buttonProps={{
                    css: {
                      backgroundColor: "#e43d30",
                      fontFamily: "KansasNew",
                      fontWeight: 700,
                      fontSize: "14px",
                      // color: "#fff",
                      "&:hover": {
                        backgroundColor: "#ffffff",
                        color: "#e43d30",
                        border: "1px solid #e43d30",
                      },
                    },
                  }}
                >
                  Pay now
                </CreditCard>
              </PaymentForm>
            </SectionCard>
          </div>
        </div>
      </Container>
    </Layout>
  )
}
// export async function getServerData(context) {
//   // console.log(context)
//   // let id = 1

//   try {
//     const token = await sessToken()
//     const res = await getCart(token)
//     console.log(res)
//     if (!res) {
//       return {
//         props: false,
//       }
//     }
//     return {
//       props: res,
//     }
//   } catch (error) {
//     return {
//       status: 500,
//       headers: {},
//       props: {},
//     }
//   }
// }
export default CheckOut
